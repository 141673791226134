import React from "react"
import styled from "styled-components"

const SMain = styled.main`
  padding: 0 4rem;
  height: 100%;
  min-height: calc(100vh - 93px);
`

export default props => (
  <SMain style={{ textAlign: props.align }}>
    <h1 class="lg-heading">
      {props.primary} <span class="secondary">{props.secondary}</span>
    </h1>
    <h3 class="sm-heading bg">
      {props.tag}
      <span class="loading">.</span> <span class="loading">.</span>{" "}
      <span class="loading">.</span>
    </h3>
    {props.children}
  </SMain>
)
