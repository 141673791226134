import React from "react"
import { Link, withPrefix } from "gatsby"
import styled from "styled-components"

const StyledLink = props => (
  <Link
    activeClassName="current"
    className="nav-link"
    style={{ padding: `1rem` }}
    to={props.to}
  >
    {props.children}
  </Link>
)

export default () => (
  <menu className="menu">
    <ul className="menu-nav">
      <li className="nav-item">
        <StyledLink to="/">
          <i className="fas fa-home" />
        </StyledLink>
      </li>
      {/* <li className="nav-item">
        <StyledLink to="/about/">
          <i className="fas fa-user-tie" />
        </StyledLink>
      </li> */}
      <li className="nav-item">
        <StyledLink to="/projects/">
          <i className="fas fa-briefcase" />
        </StyledLink>
      </li>
      {/* <li className="nav-item">
        <a target="_blank" href={withPrefix("/resume.pdf")}>
          <i className="fas fa-file-signature" />
        </a>
      </li> */}
      {/* <li className="nav-item">
        <StyledLink to="/blog/">
          <i className="fab fa-blogger-b" />
        </StyledLink>
      </li> */}
    </ul>
  </menu>
)
