import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const SH1 = styled.h1`
  color: purple;
  font-size: 3rem;
`
export default props => (
  <>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
        integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
        crossorigin="anonymous"
      />
      <title>{props.title}</title>
    </Helmet>
    <SH1>{props.text}</SH1>
  </>
)
