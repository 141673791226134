import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Menu from "../layout/menu"
import Footer from "./footer"
import { withPrefix } from "gatsby"

const LayoutWrapper = styled.div`
  margin: 0 0 0 0;
`
const SMenu = styled.menu`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 2rem 0;
`
const SH3 = styled.img`
  position: fixed;
  width: 70px;
  top: -10px;
  z-index: 99;
`

export default ({ children }) => (
  <LayoutWrapper>
    <SMenu>
      <Link to="/">
        {/* <SH3 src={withPrefix("/seunlogo2.png")} alt="" /> */}
      </Link>
      <Menu />
    </SMenu>
    <div> {children}</div>
  </LayoutWrapper>
)
